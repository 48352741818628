import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import moment from "moment";

import AppLayout from "../components/shared/app-layout";
import { numberToMoneyFormat } from "../app.utils";
import api from "../service/api";
import { handleResponseError } from "../app.utils";
import alertStore from "../store/alertStore";
import partnerAccountsStore from "../store/partnerAccountsStore";
import DateInputField from "../components/form/date-field";

const MyPartnerAccount = () => {
  const [isShowSecret, setIsShowSecret] = useState(false);
  const [data, setData] = useState([]);

  const toggleIsShowSecret = () => {
    setIsShowSecret(!isShowSecret);
  };

  const hideString = (string) => {
    const hideLength = string?.length ? string.length : 1;
    return "*".repeat(hideLength);
  };

  Chart.register(...registerables);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        offset: true,
      },
    },
    options: {
      responsive: false,
      maintainAspectRatio: false,
    },
    elements: {
      point: {
        borderWidth: 3,
        radius: 2,
      },
    },
  };

  const onHandleGetAccountBalanceHistoryData = async () => {
    try {
      const response = await Promise.all(partnerAccountsStore.accountDetails?.map((accountDetails) => api.get.accountBalanceHistory(accountDetails.id)));
      const balanceHistorys = response?.map((response) => response.data);

      const mergeTwoArrObjByKey = (targetArr, sourceArr, targetArrMergeKey, sourceArrMergeKey) => {
        const result = targetArr?.map((targetEle) => {
          const sourceAndTargetArrayHasMatchedKeysEle = sourceArr.find((sourceItem) => sourceItem[`${sourceArrMergeKey}`]?.toString() === targetEle[`${targetArrMergeKey}`]);
          return { ...targetEle, ...sourceAndTargetArrayHasMatchedKeysEle };
        });
        return result;
      };
      // merge balance history and account details whereby accountId === id
      const pageData = mergeTwoArrObjByKey(balanceHistorys, partnerAccountsStore.accountDetails, "accountId", "id");
      setData(pageData);
    } catch (error) {
      alertStore.updateAlertMessage(handleResponseError(error));
    }
  };

  useEffect(() => {
    const onHandlePageData = async () => {
      try {
        const response = await api.get.partnerAccounts();
        partnerAccountsStore.updateAccountDetails(response.data.accounts);
        partnerAccountsStore.updateApiDetails({
          accessId: response.data.accessId,
          accessSecret: response.data.accessSecret,
        });
        onHandleGetAccountBalanceHistoryData();
      } catch (error) {
        alertStore.updateAlertMessage(handleResponseError(error));
      }
    };

    onHandlePageData();
  }, []);

  return (
    <AppLayout hasHeader={true} hasFooter={true} hasSideNavbar={true}>
      <div className="app-page page-my-partner-account">
        <div className="my-partner-account">
          <div className="container-align-left">
            <div className="page-background">
              {/* <DateInputField
                                    label={"account.form.dateOfBirthField.label"}
                                    name="customerDateOfBirth"
                                    formik={formik}
                                    maxDate={minDateOfBirth}
                                /> */}
              {data?.map((row, index) => {
                const dataGraph = {
                  labels: row.transactions?.map((transaction) => transaction.month),
                  datasets: [
                    {
                      data: row.transactions?.map((transaction) => transaction.amount),
                      fill: false,
                      borderColor: "#ED702D",
                      spanGaps: true,
                    },
                  ],
                };
                return (
                  <div className="my-partner-account__tile-graph-container" key={"tile-graph" + index}>
                    <div className="my-partner-account__tile-container">
                      <div className="my-partner-account__tile-row">
                        <div className="my-partner-account__tile" style={{ backgroundColor: "#51A6F1" }}>
                          <div>
                            <div className="my-partner-account__tile-title text-bold">Currency Account</div>
                            <div className="my-partner-account__tile-subtitle">{row.currency} Account</div>
                          </div>
                          <div className="my-partner-account__tile-value-container text-bold">
                            <span className="my-partner-account__tile-value">{row.name}</span>
                          </div>
                          <div className="my-partner-account__tile-line"></div>
                        </div>
                        <div className="my-partner-account__tile" style={{ backgroundColor: "#1CC893" }}>
                          <div>
                            <div className="my-partner-account__tile-title text-bold">Total Settlements</div>
                            <div className="my-partner-account__tile-subtitle">Calculated in USD</div>
                          </div>
                          <div className="my-partner-account__tile-value-container text-bold">
                            <span className="my-partner-account__tile-value">{numberToMoneyFormat(row.settlementBalance)}</span> <span className="my-partner-account__tile-currency">USD</span>
                          </div>
                          <div className="my-partner-account__tile-line"></div>
                        </div>
                      </div>
                      <div className="my-partner-account__tile-row" style={{ backgroundColor: "#48458D" }}>
                        <div className="my-partner-account__tile">
                          <div>
                            <div className="my-partner-account__tile-title text-bold">Fund Balance</div>
                            <div className="my-partner-account__tile-subtitle">Total Currency Account Balance</div>
                          </div>
                          <div className="my-partner-account__tile-value-container text-bold">
                            <span className="my-partner-account__tile-value">{numberToMoneyFormat(row.balance)}</span> <span className="my-partner-account__tile-currency">{row.currency}</span>
                          </div>
                          <div className="my-partner-account__tile-line"></div>
                        </div>
                        <div className="my-partner-account__tile" style={{ backgroundColor: "#3A4EB1" }}>
                          <div>
                            <div className="my-partner-account__tile-title text-bold">Fund In Value</div>
                            <div className="my-partner-account__tile-subtitle">Total Fund In Transaction Value</div>
                          </div>
                          <div className="my-partner-account__tile-value-container text-bold">
                            <span className="my-partner-account__tile-value">{numberToMoneyFormat(row.fundInBalance)}</span> <span className="my-partner-account__tile-currency">USD</span>
                          </div>
                          <div className="my-partner-account__tile-line"></div>
                        </div>
                      </div>
                    </div>

                    <div className="my-partner-account__graph-container">
                      <p className="my-partner-account__graph-title text-bold">Balance History {moment().format("YYYY")}</p>
                      <p className="my-partner-account__graph-subtitle">Historical Chart</p>
                      <div className="my-partner-account__graph-wrapper">
                        <div className="my-partner-account__graph">
                          <Line options={options} data={dataGraph} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="my-partner-account__api-details">
                <div className="my-partner-account__api-details-heading">
                  <p className="my-partner-account__api-details-title text-bolt">API Details</p>
                  <p className="my-partner-account__api-details-subtitle">Partner ID & Secret</p>
                </div>
                <div className="my-partner-account__api-details-field-value-container">
                  <div className="my-partner-account__api-details-field-value text-bold">
                    <span className="my-partner-account__api-details-field">Partner ID:</span>
                    <span className="my-partner-account__api-details-value">{partnerAccountsStore.apiDetails?.accessId}</span>
                  </div>
                  <div className="my-partner-account__api-details-field-value-hide-button-container text-bold">
                    <div className="my-partner-account__api-details-field-value">
                      <span className="my-partner-account__api-details-field">Partner Secret:</span>
                      <span className="my-partner-account__api-details-value">{isShowSecret ? partnerAccountsStore.apiDetails?.accessSecret : hideString(partnerAccountsStore.apiDetails?.accessSecret)}</span>
                    </div>
                    <button className="btn btn-blue btn-m" type="button" onClick={toggleIsShowSecret}>
                      {isShowSecret ? "HIDE" : "SHOW"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default MyPartnerAccount;
