import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as constants from "../app.constant";
import { dateTimeFormatting } from "../app.utils";
import AppLayout from "../components/shared/app-layout";
import AppPagination from "../components/shared/app-pagination";
import AppTable from "../components/shared/app-table";
import api from "../service/api";
import transactionsDetailsStore from "../store/transactionsDetailsStore";
import { CellFilter, SelectCellFilter, txTypeSelectCellFilter } from "../components/shared/app-filters";
import { useDebounce } from "use-debounce";

const Transactions = () => {
  const [filter, setFilter] = useState([]);
  const [sort, setSort] = useState([]);
  const [debouncedFilter] = useDebounce(filter, 500);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerpage, setRowsPerPage] = useState(10);
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState({});
  const [pageData, setPageData] = useState({
    rowData: [],
    isLoading: false,
    totalCount: 0,
  });
  const navigate = useNavigate();
  const transactionStatusDisplayMapping = (backEndValue) => {
    switch (backEndValue) {
      case "SUCCESS":
        return "Success";
      case "PENDING":
        return "Pending";
      case "FAILED":
        return "Failed";
      case "CANCELLED":
        return "Cancelled";
      case "ERROR":
        return "Error";
    }
  };
  const onHandleNavigateTransactionDetails = (rowData) => {
    transactionsDetailsStore.updateSelectedTransaction(rowData);
    navigate(constants.ROUTE_PATH.TRANSACTIONDETAILS);
  };

  useEffect(() => {
    api.get.partnerAccounts().then((response) => {
      setAccounts(response.data.accounts);
      setAccount(response.data.accounts[0]);
    });
  }, []);

  useEffect(() => {
    const skip = (currentPage - 1) * rowsPerpage;
    const take = rowsPerpage;
    if (!account?.id) return;

    api.get.accountTransactions(account.id, skip, take, sort, filter).then((response) => {
      const { transactions, totalCount } = response.data;
      setPageData({
        rowData: transactions,
        totalCount: totalCount,
      });
    });
  }, [currentPage, debouncedFilter, sort, rowsPerpage, account]);

  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedFilter, sort, rowsPerpage, account]);

  const columns = [
    {
      Header: "Merchant TXID",
      accessor: "merchantTxId",
      canFilter: true,
      disableSortBy: true,
      Filter: CellFilter,
      Cell: (props) => (
        <button className="a-tag" onClick={() => onHandleNavigateTransactionDetails(props?.row?.original)}>
          {props.value}
        </button>
      ),
    },
    {
      Header: "Partner TXID",
      accessor: "partnerTxId",
      Filter: CellFilter,
      disableSortBy: true,
    },
    {
      Header: "Submitted Amount",
      accessor: "submitAmount",
      Filter: false,
    },
    {
      Header: "Partner Amount",
      accessor: "partnerAmount",
      Filter: false,
    },
    {
      Header: "Merchant Fees",
      accessor: "merchantFee",
      Filter: false,
    },
    {
      Header: "Status",
      accessor: "txStatus",
      Filter: SelectCellFilter,
      disableSortBy: true,
      Cell: (props) => <div className={`cell-status cell-status--${props.value.toLowerCase()}`}>{transactionStatusDisplayMapping(props.value)}</div>,
    },
    {
      Header: "Tx. Type",
      accessor: "type",
      Filter: txTypeSelectCellFilter,
      disableSortBy: true,
      Cell: (props) => <div className={`cell-status cell-status--${props.value.toLowerCase()}`}>{props.value}</div>,
    },
    {
      Header: "Routing ID",
      accessor: "psp.id",
      Filter: CellFilter,
      disableSortBy: true,
    },
    {
      Header: "Email",
      accessor: "email",
      Filter: CellFilter,
      disableSortBy: true,
    },
    {
      Header: "Date Submitted",
      accessor: "createdAt",
      Filter: false,
      Cell: (props) => dateTimeFormatting(props.value),
    },
    {
      Header: "Date Proc",
      accessor: "processedAt",
      Filter: false,
      Cell: (props) => dateTimeFormatting(props.value),
    },
  ];

  return (
    <AppLayout hasHeader={true} hasFooter={true} hasSideNavbar={true} isLoading={pageData.isLoading}>
      <div className="app-page page-transactions">
        <div className="transactions">
          <div className="container-align-left">
            {accounts && (
              <select
                className="app-table__pagination-pages-button"
                onChange={(e) => {
                  const account = accounts.find((acc) => {
                    return acc.id == e.target.value;
                  });
                  setAccount(account);
                }}
              >
                {accounts.map((e) => {
                  return (
                    <option value={e.id} key={e.id}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            )}
            <div className="transactions__table-container shadow">
              <AppTable columns={columns} data={pageData.rowData} filterHandler={setFilter} sortHandler={setSort} />
            </div>
          </div>
        </div>
      </div>
      <AppPagination totalRows={pageData.totalCount} pageChangeHandler={setCurrentPage} rowsPerPage={rowsPerpage} rowsPerPageHandler={setRowsPerPage} />
    </AppLayout>
  );
};

export default Transactions;
