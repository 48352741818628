import React, { Fragment, useState, useLayoutEffect } from "react";

import AppHeader from "./app-header";
import AppSideNavbar from "./app-side-navbar";
import AppFooter from "./app-footer";
import AppAlert from "./app-alert";
import AppLoadingSpinner from "./app-loading-spinner";

const AppLayout = ({ hasHeader, hasFooter, children, hasSideNavbar, isLoading }) => {
    const [sideNavbarActive, setSideNavbarActive] = useState(null);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const mobileBreakpoint = 991;
    const appViewhasHeaderClass = hasHeader ? "app-view has-header" : "app-view";

    const isMobileBreakpoint = (width) => {
        return width < mobileBreakpoint;
    };
    const onToggleSideNavbar = () => {
        setSideNavbarActive(!sideNavbarActive);
    };

    useLayoutEffect(() => {
        
    const handleSidebarActivationWindowSize = () => {
        setScreenWidth(window.innerWidth);
        isMobileBreakpoint(window.innerWidth) ? setSideNavbarActive(false) : setSideNavbarActive(true);
    }

    handleSidebarActivationWindowSize();

    window.addEventListener("resize", handleSidebarActivationWindowSize);

    return () => {
        window.removeEventListener("resize", handleSidebarActivationWindowSize);
    };
    
    }, []);

    return (
        <Fragment>
            {isLoading && (<AppLoadingSpinner isVisible={isLoading}></AppLoadingSpinner>)}
            <div className={appViewhasHeaderClass}>
                <AppAlert />
                {hasHeader && <AppHeader hasSideNavbar={hasSideNavbar} isMobileBreakpoint={isMobileBreakpoint(screenWidth)} sideNavbarActive={sideNavbarActive} onToggleSideNavbar={onToggleSideNavbar} />}

                <div className="app-box-container">
                    {hasSideNavbar && sideNavbarActive && <AppSideNavbar isMobileBreakpoint={isMobileBreakpoint(screenWidth)} onToggleSideNavbar={onToggleSideNavbar} />}
                    <div className={hasSideNavbar && sideNavbarActive ? "app-content-footer-container app-content-footer-container--has-sidebar" : "app-content-footer-container"}>
                        <main className="app-content">{children}</main>
                        {hasFooter && <AppFooter />}
                    </div>
                </div>
                
            </div>
        </Fragment>
    );
};

export default AppLayout;
