import { makeAutoObservable } from "mobx";
import { makePersistable } from 'mobx-persist-store';

class ProfileStore {
    constructor() {
        makeAutoObservable(this);
        makePersistable(this, { name: "ProfileStore", properties: ["profileDetails"], storage: window.localStorage });
    }
    
    profileDetails = null;

    updateProfile = (profileDetails) => {
        this.profileDetails = profileDetails;
    };
}

const profileStore = new ProfileStore();

export default profileStore;
