import React, {useEffect} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import queryString from 'query-string';
import { useNavigate, useLocation } from "react-router-dom";

import api from "../service/api";
import * as constants from "../app.constant";
import { handleResponseError } from "../app.utils";
import alertStore from "../store/alertStore";
import AppInfoBox from "../components/shared/app-info-box";
import AppLayout from "../components/shared/app-layout";
import InputField from "../components/form/input-field";

const ResetPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required("please enter a new password"),
            confirmPassword: Yup.string()
                .required("please enter confirm password")
                .oneOf([Yup.ref('password'), null], 'New passwords must match'),
        }),
        onSubmit: (values, action) => {
            onHandleResetPassword();
        },
    });

    const searchParams = queryString.parse(location.search);

    const onHandleResetPassword = async() => {
        try {
            const payload = {
                token: searchParams?.resetToken,
                password: formik.values.password,
                confirmPassword: formik.values.confirmPassword,
            };

            const response = await api.post.resetPassword(payload);
            navigate(constants.ROUTE_PATH.LOGIN);
            setTimeout(()=> alert("Password successfully changed. Please login with new password."), 100);
        }
        catch(error) {
            alertStore.updateAlertMessage(handleResponseError(error));
        }
        formik.setSubmitting(false);
    }

    useEffect(() => {
        if (!searchParams?.resetToken) {
            navigate(constants.ROUTE_PATH.LOGIN);
        }
    }, []);

    return (
        <AppLayout>
        
            <div className="app-page page-reset-password">
                <div className="reset-password">
                    <div className="reset-password__box-wrapper">

                        <div className="reset-password__background-image"/>
                        
                        <form
                            className="reset-password__form"
                            autoComplete="off"
                            onSubmit={formik.handleSubmit}
                        >
                            <div className="reset-password__info">
                                <AppInfoBox message={"Please enter your desired new password"}/>
                            </div>
                            <InputField
                                label={"Password"}
                                placeholder={"********"}
                                name="password"
                                type="password"
                                formik={formik}
                            />
                            <InputField
                                label={"Confirm Password"}
                                placeholder={"********"}
                                name="confirmPassword"
                                formik={formik}
                                type="password"
                            />
                            <p className="btn-container btn-container--center">
                                <button className="w-100 btn btn-lg btn-primary" type="submit">
                                    reset password
                                </button>
                            </p>
                        </form>

                    </div>
                </div>
            </div>

        </AppLayout>
    );
};

export default ResetPassword;
