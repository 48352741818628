import React, { useImperativeHandle, forwardRef, useState, useMemo } from "react";

import AppInfoBox from "../../../shared/app-info-box";

const CallbackAlertModal = (props, ref) => {
    const [visible, setVisible] = useState(false);

    const onHandleShow = () => {
        setVisible(true);
    };

    const onHandleHide = () => {
        setVisible(false);
    };

    useImperativeHandle(ref, () => {
        return {        
            onHandleShow: () => onHandleShow(),
            onHandleHide: () => onHandleHide()
        }
    });

    const onHandleGetModalClassName = useMemo(() => {
        const classNames = ["alert-modal", "alert-modal--hidden"];

        if (visible) classNames.pop();

        return classNames.join(" ");
    }, [visible]);

    return (
        <>
            <div className={"callback-alert-modal " + onHandleGetModalClassName}>
                <div className="modal-wrapper">
                    <div className="modal-header">
                        <p className="callback-alert-modal__title text-bold">Resend Callback</p>
                        <p className="callback-alert-modal__subtitle text-bold">Please avoid excessive callback resend requests as it will flood the callback queue</p>
                    </div>
                    <div className="modal-body">
                        <div className="callback-alert-modal__info-container">
                            <AppInfoBox message={"Callback successfully resent!"}/>
                        </div>
                        <div className="callback-alert-modal__api-response-container">
                            <div className="callback-alert-modal__api-response-field text-bold">Response:</div>
                            <div className="callback-alert-modal__api-response-field-box ">
                                <p className="callback-alert-modal__api-response-value text-bold">200 OK!</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <p className="btn-container btn-container--center">
                            <button className="btn btn-primary-purple btn-m" type="button" onClick={props.onHandleConfirm}>Done</button>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default forwardRef(CallbackAlertModal);
