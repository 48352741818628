import * as axs from "axios";

const axios = {
    get: async (url, params) => {
        const endPointUrl = getEndPointUrl(url);
        const headers = getHeaders();

        return axs.get(endPointUrl, { params, ...headers });
    },
    post: async (url, body) => {
        const endPointUrl = getEndPointUrl(url);
        const headers = getHeaders();

        return axs.post(endPointUrl, body, headers);
    },
};

const getHeaders = () => {

    let headers = { "Content-Type": "application/json" };
    let token = null;
    const authToken = localStorage.getItem("@storage_token");
    const paymentPageToken = localStorage.getItem("@storage_payment-page-token");
    const isPaymentPage = window.location.pathname === "/payment";

    isPaymentPage ? token = paymentPageToken : token = authToken;

    if (token) {
        headers = Object.assign({}, headers, {
            Authorization: "Bearer " + token,
        });
    }

    return { headers };
};

const getEndPointUrl = (path) => {
    const endpoint = process.env.REACT_APP_PAYVERSA_API_URL + path;
    return endpoint;
};

export default axios;
