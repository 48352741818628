import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import AppLayout from "../../components/shared/app-layout";
import { numberToMoneyFormat, dateTimeFormatting } from "../../app.utils";
import CallbackAlertModal from "../../components/pages/transactions/transactions-details/callbackAlertModal";
import transactionsDetailsStore from "../../store/transactionsDetailsStore";
import * as constants from "../../app.constant";
import api from "../../service/api";
import { handleResponseError } from "../../app.utils";
import alertStore from "../../store/alertStore";

const TransactionDetails = () => {
  const navigate = useNavigate();
  const callbackAlertModal = useRef();
  const transactionStatusDisplayMapping = (backEndValue) => {
    switch (backEndValue) {
      case "SUCCESS":
        return "Success";
      case "PENDING":
        return "Pending";
      case "FAILED":
        return "Failed";
      case "CANCELLED":
        return "Cancelled";
      case "ERROR":
        return "Error";
    }
  };

  const onNavigateToTransactionPage = () => {
    navigate(constants.ROUTE_PATH.TRANSACTIONS);
  };

  const onHandleShowCallbackAlertModal = () => callbackAlertModal.current.onHandleShow();
  const onHandleHideCallbackAlertModal = () => callbackAlertModal.current.onHandleHide();

  const onHandleResendCallback = async () => {
    try {
      const payload = {
        merchantTxId: transactionsDetailsStore.selectedTransaction.merchantTxId,
      };
      const response = await api.post.resendCallback(payload);
      onHandleShowCallbackAlertModal();
    } catch (error) {
      alertStore.updateAlertMessage(handleResponseError(error));
    }
  };

  useEffect(() => {
    const isNoTransactionDetailsData = transactionsDetailsStore.selectedTransaction.length === 0;
    if (isNoTransactionDetailsData) {
      onNavigateToTransactionPage();
    }
  }, []);

  return (
    <AppLayout hasHeader={true} hasFooter={true} hasSideNavbar={true}>
      <CallbackAlertModal ref={callbackAlertModal} onHandleConfirm={() => onHandleHideCallbackAlertModal()} />

      <div className="app-page page-transactions-details">
        <div className="transactions-details">
          <div className="container-align-left">
            <div className="page-background">
              <div className="transactions-details__cta-container">
                <button className="btn btn-outline btn-m" onClick={() => onNavigateToTransactionPage()}>
                  Back
                </button>
                <button className="btn btn-m btn-blue" onClick={() => onHandleResendCallback()}>
                  Resend Callback
                </button>
              </div>

              <div className="transactions-details__tile-container">
                <div className="transactions-details__tile shadow">
                  <div>
                    <div className="transactions-details__tile-title text-bold">Transaction ID</div>
                    <div className="transactions-details__tile-subtitle">Merchant TXID</div>
                  </div>
                  <div className="transactions-details__tile-value-container text-bold">
                    <span className="transactions-details__tile-value">{transactionsDetailsStore.selectedTransaction.merchantTxId}</span>
                  </div>
                  <div className="transactions-details__tile-line" style={{ backgroundColor: "#9687D8" }}></div>
                </div>
                <div className="transactions-details__tile shadow">
                  <div>
                    <div className="transactions-details__tile-title text-bold">Transaction ID</div>
                    <div className="transactions-details__tile-subtitle">Partner TXID</div>
                  </div>
                  <div className="transactions-details__tile-value-container text-bold">
                    <span className="transactions-details__tile-value">{transactionsDetailsStore.selectedTransaction.partnerTxId}</span>
                  </div>
                  <div className="transactions-details__tile-line" style={{ backgroundColor: "#87D88F" }}></div>
                </div>
                <div className="transactions-details__tile shadow">
                  <div>
                    <div className="transactions-details__tile-title text-bold">Submitted Amount</div>
                    <div className="transactions-details__tile-subtitle">Partner value in selected currency</div>
                  </div>
                  <div className="transactions-details__tile-value-container text-bold">
                    <span className="transactions-details__tile-value">{numberToMoneyFormat(transactionsDetailsStore.selectedTransaction.submitAmount)}</span> <span className="transactions-details__tile-currency">{transactionsDetailsStore.selectedTransaction.currency}</span>
                  </div>
                  <div className="transactions-details__tile-line" style={{ backgroundColor: "#FFFFFF" }}></div>
                </div>
                <div className="transactions-details__tile shadow">
                  <div>
                    <div className="transactions-details__tile-title text-bold">Transaction Status</div>
                    <div className="transactions-details__tile-subtitle">Partner value in selected currency</div>
                  </div>
                  <div className="transactions-details__tile-value-container text-bold">
                    <span className="transactions-details__tile-value">{transactionStatusDisplayMapping(transactionsDetailsStore.selectedTransaction.txStatus)}</span>
                  </div>
                  <div className="transactions-details__tile-line" style={{ backgroundColor: "#448B73" }}></div>
                </div>
                <div className="transactions-details__tile shadow">
                  <div>
                    <div className="transactions-details__tile-title text-bold">Transaction Type</div>
                    <div className="transactions-details__tile-subtitle"></div>
                  </div>
                  <div className="transactions-details__tile-value-container text-bold">
                    <span className="transactions-details__tile-value">{transactionsDetailsStore.selectedTransaction.type}</span>
                  </div>
                  <div className="transactions-details__tile-line" style={{ backgroundColor: "#FFC107" }}></div>
                </div>
              </div>

              <div className="transactions-details__info">
                <div className="transactions-details__info-section">
                  <div className="transactions-details__info-field-value-container">
                    <div className="transactions-details__info-field-seperator-container">
                      <span className="transactions-details__info-field text-bold">Partner Amount</span>
                      <span className="transactions-details__info-seperator">:</span>
                    </div>
                    <div className="transactions-details__info-value">{numberToMoneyFormat(transactionsDetailsStore.selectedTransaction.partnerAmount)}</div>
                  </div>

                  <div className="transactions-details__info-field-value-container">
                    <div className="transactions-details__info-field-seperator-container">
                      <span className="transactions-details__info-field text-bold">Partner Currency</span>
                      <span className="transactions-details__info-seperator">:</span>
                    </div>
                    <div className="transactions-details__info-value">{transactionsDetailsStore.selectedTransaction.currency}</div>
                  </div>

                  <div className="transactions-details__info-field-value-container">
                    <div className="transactions-details__info-field-seperator-container">
                      <span className="transactions-details__info-field text-bold">Merchant Fees</span>
                      <span className="transactions-details__info-seperator">:</span>
                    </div>
                    <div className="transactions-details__info-value">{numberToMoneyFormat(transactionsDetailsStore.selectedTransaction.merchantFee)}</div>
                  </div>

                  <div className="transactions-details__info-field-value-container">
                    <div className="transactions-details__info-field-seperator-container">
                      <span className="transactions-details__info-field text-bold">Routing ID</span>
                      <span className="transactions-details__info-seperator">:</span>
                    </div>
                    <div className="transactions-details__info-value">{transactionsDetailsStore.selectedTransaction.psp?.id}</div>
                  </div>

                  <div className="transactions-details__info-field-value-container">
                    <div className="transactions-details__info-field-seperator-container">
                      <span className="transactions-details__info-field text-bold">Submitted</span>
                      <span className="transactions-details__info-seperator">:</span>
                    </div>
                    <div className="transactions-details__info-value">{dateTimeFormatting(transactionsDetailsStore.selectedTransaction.createdAt)}</div>
                  </div>

                  <div className="transactions-details__info-field-value-container">
                    <div className="transactions-details__info-field-seperator-container">
                      <span className="transactions-details__info-field text-bold">Processed</span>
                      <span className="transactions-details__info-seperator">:</span>
                    </div>
                    <div className="transactions-details__info-value">{dateTimeFormatting(transactionsDetailsStore.selectedTransaction.processedAt)}</div>
                  </div>

                  <div className="transactions-details__info-field-value-container">
                    <div className="transactions-details__info-field-seperator-container">
                      <span className="transactions-details__info-field text-bold">Callback</span>
                      <span className="transactions-details__info-seperator">:</span>
                    </div>
                    <div className="transactions-details__info-value">{transactionsDetailsStore.selectedTransaction.callback}</div>
                  </div>

                  <div className="transactions-details__info-field-value-container">
                    <div className="transactions-details__info-field-seperator-container">
                      <span className="transactions-details__info-field text-bold">Account</span>
                      <span className="transactions-details__info-seperator">:</span>
                    </div>
                    <div className="transactions-details__info-value">{transactionsDetailsStore.selectedTransaction.account?.name}</div>
                  </div>
                </div>
                <div className="transactions-details__info-section">
                  <div className="transactions-details__info-field-value-container">
                    <div className="transactions-details__info-field-seperator-container">
                      <span className="transactions-details__info-field text-bold">Email</span>
                      <span className="transactions-details__info-seperator">:</span>
                    </div>
                    <div className="transactions-details__info-value">{transactionsDetailsStore.selectedTransaction.email}</div>
                  </div>

                  <div className="transactions-details__info-field-value-container">
                    <div className="transactions-details__info-field-seperator-container">
                      <span className="transactions-details__info-field text-bold">Name</span>
                      <span className="transactions-details__info-seperator">:</span>
                    </div>
                    <div className="transactions-details__info-value">{transactionsDetailsStore.selectedTransaction.name}</div>
                  </div>

                  <div className="transactions-details__info-field-value-container">
                    <div className="transactions-details__info-field-seperator-container">
                      <span className="transactions-details__info-field text-bold">Country</span>
                      <span className="transactions-details__info-seperator">:</span>
                    </div>
                    <div className="transactions-details__info-value">{transactionsDetailsStore.selectedTransaction.country}</div>
                  </div>

                  <div className="transactions-details__info-field-value-container">
                    <div className="transactions-details__info-field-seperator-container">
                      <span className="transactions-details__info-field text-bold">State</span>
                      <span className="transactions-details__info-seperator">:</span>
                    </div>
                    <div className="transactions-details__info-value">{transactionsDetailsStore.selectedTransaction.state}</div>
                  </div>

                  <div className="transactions-details__info-field-value-container">
                    <div className="transactions-details__info-field-seperator-container">
                      <span className="transactions-details__info-field text-bold">Address</span>
                      <span className="transactions-details__info-seperator">:</span>
                    </div>
                    <div className="transactions-details__info-value">{transactionsDetailsStore.selectedTransaction.address}</div>
                  </div>
                </div>
              </div>

              {/* <div className="transactions-details__api-request">
                                <p className="transactions-details__api-request-title text-bold">Raw Response</p>
                                <p className="transactions-details__api-request-subtitle">API response params from partner platform</p>
                                <pre className="transactions-details__api-request-subtitle">{JSON.stringify(transactionsDetailsStore.selectedTransaction.response, null, "\t")}</pre>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default TransactionDetails;
