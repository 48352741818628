import React from "react";
import { MdInfo } from "react-icons/md";

const AppInfoBox = ({ message }) => {
    return (
        <div className="app-info-box">
            <div className="app-info-box__icon-info-message-container">
                <MdInfo size={54}/>
                <span className="app-info-box__message text-bold">{message}</span>
            </div>
        </div>
    );
};

export default AppInfoBox;
