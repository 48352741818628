import React, { useRef, useEffect, useState } from "react";
import { observer } from "mobx-react";
import moment from "moment";

import AppLayout from "../components/shared/app-layout";
import Table from "../components/shared/app-table";
import SelectColumnFilter from "../components/shared/app-table-select-filter";
import RequestSettlementModal from "../components/pages/settlements/requestSettlementModal";
import { numberToMoneyFormat } from "../app.utils";
import api from "../service/api";
import { handleResponseError, dateTimeFormatting } from "../app.utils";
import partnerAccountsStore from "../store/partnerAccountsStore";
import alertStore from "../store/alertStore";

const Settlements = observer(() => {
    const [settlements, setSettlements] = useState([]);
    const sortedSettlementsBySubmittedDateDesc = settlements?.sort((a, b) => moment(b.createdAt) - moment(a.createdAt));

    const requestSettlementModal = useRef();

    const settlementsStatusDisplayMapping = (backEndValue) => {
        switch (backEndValue) {
            case "SUCCESS":
                return "Success";
            case "PENDING":
                return "Pending";
            case "FAILED":
                return "Failed";
            case "CANCELLED":
                return "Cancelled";
            case "ERROR":
                return "Error";
        }
    };

    const accountsData = partnerAccountsStore.accountDetails;
    
    const settlementsTableCol = [
        {
            Header: "Settlement ID",
            accessor: "settlementId",
            Filter: ({column: {filterValue, setFilter}}) => {
                return (
                    <input
                        value={filterValue === undefined ? "" : filterValue}
                        placeholder={" search filter..."}
                        onChange={e => {
                            setFilter(e.target.value || undefined)
                        }}
                    />

                )
            },
        },
        {
            Header: "Amount",
            accessor: "amount",
            Filter: false,
        },
        {
            Header: "Destination",
            accessor: "destinationAccount",
            Filter: ({column: {filterValue, setFilter}}) => {
                return (
                    <input
                        value={filterValue === undefined ? "" : filterValue}
                        placeholder={" search filter..."}
                        onChange={e => {
                            setFilter(e.target.value || undefined)
                        }}
                    />

                )
            },
        },
        {
            Header: "Account",
            accessor: "walletAddress",
            Filter: ({column: {filterValue, setFilter}}) => {
                return (
                    <input
                        value={filterValue === undefined ? "" : filterValue}
                        placeholder={" search filter..."}
                        onChange={e => {
                            setFilter(e.target.value || undefined)
                        }}
                    />

                )
            },
        },
        {
            Header: "Status",
            accessor: "status",
            Filter: SelectColumnFilter,
            filter: 'includes',
            Cell: props => <div className={`cell-status cell-status--${props.value.toLowerCase()}`}>{settlementsStatusDisplayMapping(props.value)}</div>
        },
        {
            Header: "Attachment",
            accessor: "attachment",
            Filter: false,
            Cell: props => <a href={props.value} target="_blank">transfer-receipt</a>
        },
        {
            Header: "Date Submitted",
            accessor: "createdAt",
            Filter: false,
            Cell: props => dateTimeFormatting(props.value)
        },
        {
            Header: "Date Approved",
            accessor: "approvedAt",
            Filter: false,
            Cell: props => dateTimeFormatting(props.value)
        },
    ];

    const onHandleShowRequestSettlementModal = () => requestSettlementModal.current.onHandleShow();
    const onHandleHideRequestSettlementModal = () => requestSettlementModal.current.onHandleHide();

    const onHandleRequestSettlement = () => {
        onHandleShowRequestSettlementModal();
    }

    const onHandleGetPartnerAccounts = async () => {
        try {
            const response = await api.get.partnerAccounts();
            partnerAccountsStore.updateAccountDetails(response.data.accounts);
        }
        catch(error) {
            alertStore.updateAlertMessage(handleResponseError(error));
        }
    };
    const onHandleGetSettlements = async () => {
        try {
            const response = await api.get.settlements();
            setSettlements(response.data);
        }
        catch(error) {
            alertStore.updateAlertMessage(handleResponseError(error));
        }
    };

    useEffect(() => {
        onHandleGetPartnerAccounts()
        onHandleGetSettlements()
    }, []);

    const onHandleCreateNewSettlement = async (userInput, formik) => {
        try {
            const payload = {
                amount: userInput.amount,
                destinationAccount: "WALLET",
                walletNetwork: userInput.walletNetwork,
                walletAddress: userInput.walletAddress,
                accountId: userInput.selectedAccount,
                tacCode: userInput.tacCode.toString()
            };

            const response = await api.post.newSettlement(payload);
            onHandleHideRequestSettlementModal()
            formik.setSubmitting(false);
            onHandleGetPartnerAccounts();
            onHandleGetSettlements();
            setTimeout(()=> alert("New settlement made."), 100);
        }
        catch(error) {
            alertStore.updateAlertMessage(handleResponseError(error));
            formik.setSubmitting(false);
        }
    };

    const onHandleRequestTac = async (formik) => {
        try {
            const response = await api.post.requestTac();
            formik.setSubmitting(false);
        }
        catch(error) {
            alertStore.updateAlertMessage(handleResponseError(error));
            formik.setSubmitting(false);
        }
    };

    return (
        <AppLayout hasHeader={true} hasFooter={true} hasSideNavbar={true}>

            <RequestSettlementModal accountsData={accountsData} onHandleCreateNewSettlement={onHandleCreateNewSettlement} onHandleRequestTac={onHandleRequestTac} ref={requestSettlementModal} onHandleCancel={() => onHandleHideRequestSettlementModal()}/>
            <div className="app-page page-settlements">
                <div className="settlements">
                    <div className="container-align-left">
                        <div className="page-background">

                            <div className="settlements__tile-container">
                                <div className="settlements__tile">
                                    <div>
                                        <p className="settlements__tile-title text-bold">{accountsData[0]?.name} Fund Balance</p>
                                        <p className="settlements__subtitle">Total Currency Account Balance</p>
                                    </div>
                                    <p className="settlements__tile-value-container text-bold">
                                        <span className="settlements__tile-value">{numberToMoneyFormat(accountsData[0]?.balance)}</span> <span className="settlements__tile-currency">{accountsData[0]?.currency}</span>
                                    </p>
                                    <div className="settlements__tile-white-line"></div>
                                </div>
                                <div className="settlements__tile">
                                    <div>
                                        <p className="settlements__tile-title text-bold">{accountsData[1]?.name} Fund Balance</p>
                                        <p className="settlements__subtitle">Total Currency Account Balance</p>
                                    </div>
                                    <p className="settlements__tile-value-container text-bold">
                                        <span className="settlements__tile-value">{numberToMoneyFormat(accountsData[1]?.balance)}</span> <span className="settlements__tile-currency">{accountsData[1]?.currency}</span>
                                    </p>
                                    <div className="settlements__tile-white-line"></div>
                                </div>
                            </div>

                            <div className="settlements__table-container">
                                <p>
                                    <button className="btn btn-m btn-dark-blue" onClick={() => onHandleRequestSettlement()}>Request Settlement</button>
                                </p>
                                <Table
                                    columns={settlementsTableCol}
                                    data={sortedSettlementsBySubmittedDateDesc}
                                    pagination={true}
                                    paginationRowPerPage={10}
                                    isQueryFilterEnabled={true}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
});

export default Settlements;
