import { makeAutoObservable } from "mobx";
import { makePersistable } from 'mobx-persist-store';

class PartnerAccountsStore {
    constructor() {
        makeAutoObservable(this);
        makePersistable(this, { name: "PartnerAccountsStore", properties: ["accountDetails"], storage: window.localStorage });
    }
    
    accountDetails = [];
    apiDetails = null;

    updateAccountDetails = (accountDetails) => {
        this.accountDetails = accountDetails;
    };

    updateApiDetails = (apiDetails) => {
        this.apiDetails = apiDetails;
    };
}

const partnerAccountsStore = new PartnerAccountsStore();

export default partnerAccountsStore;
