import React, { useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import * as constants from "../../app.constant";
import api from "../../service/api";
import { handleResponseError } from "../../app.utils";
import alertStore from "../../store/alertStore";
import AppInfoBox from "../../components/shared/app-info-box";
import AppLayout from "../../components/shared/app-layout";
import InputField from "../../components/form/input-field";

const PasswordReset = () => {
    const navigate = useNavigate();
    const onNavigateToMyProfilePage = () => {
        navigate(constants.ROUTE_PATH.MYPROFILE);
    }; 
    const onNavigateToLoginPage = () => {
        navigate(constants.ROUTE_PATH.LOGIN);
    }; 

    const formik = useFormik({
        initialValues: {
            currentPassword:"",
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            currentPassword: Yup.string()
                .required("please enter your current password"),
            newPassword: Yup.string()
                .required("please enter new password"),
            confirmPassword: Yup.string()
                .required("please enter repeat password")
                .oneOf([Yup.ref('newPassword'), null], 'New passwords must match'),
        }),
        onSubmit: (values, action) => {
            onHandleChangePassword();
        },
    });

    const onNavigateToLogout = () => {
        navigate(constants.ROUTE_PATH.LOGIN);
    }; 

    const onHandleChangePassword = async () => {
        try {
            const payload = {
                currentPassword:  formik.values.currentPassword,
                newPassword: formik.values.newPassword,
                confirmPassword: formik.values.confirmPassword,
            };
            const response = await api.post.changePassword(payload);  
            localStorage.clear();
            onNavigateToLogout();
            setTimeout(()=> alert("Password changed. Please login with new password."), 200);
        }
        catch(error) {
            alertStore.updateAlertMessage(handleResponseError(error));
        }
        formik.setSubmitting(false);
    };

    return (
        <AppLayout hasHeader={true} hasFooter={true} hasSideNavbar={true}>
            <div className="container-align-left">
                <div className="app-page page-password-reset">
                    <div className="password-reset">
                        <div className="password-reset__wrapper">

                            <div className="password-reset__header">
                                <p className="password-reset__title text-bold">Password Reset</p>
                                <p className="password-reset__subtitle text-bold">Insert New Password</p>
                            </div>

                            <div className="password-reset__info">
                                <AppInfoBox message={"Please enter matching passwords"}/>
                            </div>
                            
                            <form
                                className="password-reset__form"
                                autoComplete="off"
                                onSubmit={formik.handleSubmit}
                            >
                                <InputField
                                    label="Current Password"
                                    placeholder={"********"}
                                    name="currentPassword"
                                    formik={formik}
                                    type="password"
                                />
                                <InputField
                                    label="New Password"
                                    placeholder={"********"}
                                    name="newPassword"
                                    formik={formik}
                                    type="password"
                                />
                                <InputField
                                    label="Repeat Password"
                                    placeholder={"********"}
                                    name="confirmPassword"
                                    formik={formik}
                                    type="password"
                                />

                                <p className="btn-container">
                                    <button className="btn btn-primary-purple btn-m" type="submit" disabled={formik.isSubmitting}>
                                        Yes
                                    </button>
                                    <button className="btn btn-secondary-red btn-m" onClick={() => onNavigateToMyProfilePage()} type="button" disabled={formik.isSubmitting}>
                                        No
                                    </button>
                                </p>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default PasswordReset;
