import React, { useEffect, useMemo } from "react";
import { useFilters, useSortBy, useTable } from "react-table";

const AppTable = ({ columns, data, filterHandler, sortHandler }) => {
    const columnData = useMemo(() => columns, [columns]);
    const rowData = useMemo(() => data, [data]);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
        {
            columns: columnData,
            data: rowData,
            manualFilters: true,
            manualSortBy: true,
            isLoading: true,
        },
        useFilters,
        useSortBy
    );

    useEffect(() => {
        if (sortHandler) sortHandler(state.sortBy);
        if (filterHandler) filterHandler(state.filters);
    }, [state]);

    return (
        <div className="app-table">
            <div className="table-responsive">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render("Header")}
                                        <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                                        <div>{column.canFilter ? column.render("Filter") : null}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={`row-${i}`}>
                                    {row.cells.map((cell) => {
                                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AppTable;
