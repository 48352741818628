import React from "react";
import { useNavigate } from "react-router-dom";

import AppLayout from "../components/shared/app-layout";
import * as constants from "../app.constant";
import profileStore from "../store/profileStore";

const MyProfile = () => {
    const navigate = useNavigate();
    const onNavigateToPasswordResetPage = () => {
        navigate(constants.ROUTE_PATH.PASSWORDRESET);
    }; 
    
    return (
        <AppLayout hasHeader={true} hasFooter={true} hasSideNavbar={true}>
            <div className="app-page page-my-profile">
                <div className="my-profile">
                    <div className="container-align-left">
                        <div className="page-background">
                            <div className="my-profile__logo-info-container">
                                <div className="my-profile__logo">
                                    <img className="img-fluid" alt="" src={profileStore?.profileDetails?.image} />
                                </div>
                                <div className="my-profile__info">

                                    <div className="my-profile__info-field-value-container">
                                        <div className="my-profile__info-field-seperator-container">
                                            <span className="my-profile__info-field text-bold">Partner Name</span>
                                            <span className="my-profile__info-seperator">:</span>
                                        </div>
                                        <div className="my-profile__info-value">{profileStore?.profileDetails?.name}</div>
                                    </div>
                                    
                                    <div className="my-profile__info-field-value-container">
                                        <div className="my-profile__info-field-seperator-container">
                                            <span className="my-profile__info-field text-bold">Email Address</span> 
                                            <span className="my-profile__info-seperator">:</span>
                                        </div>
                                        <div className="my-profile__info-value">{profileStore?.profileDetails?.email}</div>
                                    </div>

                                    <div className="my-profile__info-field-value-container">
                                        <div className="my-profile__info-field-seperator-container">
                                            <span className="my-profile__info-field text-bold">Password</span> 
                                            <span className="my-profile__info-seperator">:</span>
                                        </div>
                                        <div className="my-profile__info-value-reset-button">
                                            <span>******</span>
                                            <button className="btn btn-blue btn-m" onClick={()=> onNavigateToPasswordResetPage()} type="button">Reset</button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default MyProfile;
