import axios from "../service/axios";
import { ENDPOINT_PATH } from "../app.constant";

const api = {
    get: {
        profile: () => axios.get(ENDPOINT_PATH.PROFILE),
        partnerAccounts: () => axios.get(ENDPOINT_PATH.PARTNER_ACCOUNTS),
        accountTransactions: async (accountId, skip, take, sort, filter) => {
            const path = `${ENDPOINT_PATH.ACCOUNT_TRANSACTIONS}${accountId}`;
            const s = sort.length === 0 ? JSON.stringify([{ field: "" }]) : JSON.stringify(sort.map((sort) => ({ field: sort.id, type: sort.desc ? "desc" : "asc" })));
            const f = JSON.stringify(filter.map((filter) => ({ [filter.id]: filter.value })).reduce((acc, cur) => ({ ...acc, ...cur }), {}));
            return await axios.get(`${path}?skip=${skip}&take=${take}&sort=${s}&filter=${f}`);
        },
        accountBalanceHistory: (accountId) => axios.get(`${ENDPOINT_PATH.ACCOUNT_BALANCE_HISTORY}${accountId}`),
        settlements: () => axios.get(ENDPOINT_PATH.SETTLEMENTS),
        getDashboard: () => axios.get(ENDPOINT_PATH.GET_DASHBOARD),
    },
    post: {
        login: (payload) => axios.post(ENDPOINT_PATH.LOGIN, payload),
        forgotPassword: (payload) => axios.post(ENDPOINT_PATH.FORGOT_PASSWORD, payload),
        resetPassword: (payload) => axios.post(ENDPOINT_PATH.RESET_PASSWORD, payload),
        changePassword: (payload) => axios.post(ENDPOINT_PATH.CHANGE_PASSWORD, payload),
        resendCallback: (payload) => axios.post(ENDPOINT_PATH.RESEND_CALLBACK, payload),
        gatewayGetAccessToken: (payload) => axios.post(ENDPOINT_PATH.GATEWAY_GET_ACCESS_TOKEN, payload),
        gatewaySubmit: (payload) => axios.post(ENDPOINT_PATH.GATEWAY_SUBMIT, payload),
        gatewayCancel: (payload) => axios.post(ENDPOINT_PATH.GATEWAY_CANCEL, payload),
        newSettlement: (payload) => axios.post(ENDPOINT_PATH.NEW_SETTLEMENT, payload),
        requestTac: () => axios.post(ENDPOINT_PATH.REQUEST_TAC),
    },
};

export default api;
