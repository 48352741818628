import React, {useRef} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import api from "../service/api";
import * as constants from "../app.constant";
import { handleResponseError } from "../app.utils";
import alertStore from "../store/alertStore";
import AppInfoBox from "../components/shared/app-info-box";
import AppLayout from "../components/shared/app-layout";
import InputField from "../components/form/input-field";
import partnerLoginLogo from "../assets/images/pages/page-login/partner-login-logo.svg";
import ForgetPasswordModal from "../components/pages/login/forgetPasswordModal";


const Login = () => {
    const navigate = useNavigate();
    const forgetPasswordModal = useRef();

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("invalid email format")
                .required("please enter email"),
            password: Yup.string()
                .required("please enter password"),
        }),
        onSubmit: (values, action) => {
            onHandleLogin();
        },
    });

    const onHandleLogin = async () => {
        try {
            const payload = {
                email: formik.values.email,
                password: formik.values.password,
            };
            const response = await api.post.login(payload);
            localStorage.setItem("@storage_token", response.data.token);
            navigate(constants.ROUTE_PATH.DASHBOARD);
        }
        catch(error) {
            alertStore.updateAlertMessage(handleResponseError(error));
        }
        formik.setSubmitting(false);
    };

    const onHandleShowForgetPasswordModal = () => forgetPasswordModal.current.onHandleShow();
    const onHandleHideForgetPasswordModal = () => forgetPasswordModal.current.onHandleHide();

    const onHandleForgetPassword = async(userInput) => {
        try {
            const payload = {
                email: userInput.forgetPasswordEmail,
            };
            const response = await api.post.forgotPassword(payload);
            onHandleHideForgetPasswordModal()
            setTimeout(()=> alert("Reset password link sent. Please check your account email."), 100);
        }
        catch(error) {
            alertStore.updateAlertMessage(handleResponseError(error));
        }
    }

    return (
        <AppLayout isLoading={formik.isSubmitting}>
            
            <ForgetPasswordModal ref={forgetPasswordModal} onHandleForgetPassword={onHandleForgetPassword} onHandleCancel={() =>  onHandleHideForgetPasswordModal()}/>

            <div className="app-page page-login">
                <div className="login">
                    <div className="login__box-wrapper">

                        <div className="login__background-image"/>
                        
                        <form
                            className="login__form"
                            autoComplete="off"
                            onSubmit={formik.handleSubmit}
                        >
                            <div className="login__info">
                                <AppInfoBox message={"Please enter your credentials"}/>
                            </div>
                            <div className="login__logo">
                                <img src={partnerLoginLogo} alt="payversa" />
                            </div>
                            <InputField
                                label={"Email Address"}
                                placeholder={"name@mail.com"}
                                name="email"
                                formik={formik}
                            />
                            <InputField
                                label={"Password"}
                                placeholder={"********"}
                                name="password"
                                formik={formik}
                                type="password"
                            />
                            <p className="login__forget-password-btn-container">
                                <button type="button" className="login__forget-password-button float-right" onClick={() => onHandleShowForgetPasswordModal()}>Forget Password</button>
                            </p>
                            <p className="btn-container btn-container--center">
                                <button className="w-100 btn btn-lg btn-primary" type="submit" disabled={formik.isSubmitting}>
                                    Login
                                </button>
                            </p>
                        </form>

                    </div>
                </div>
            </div>

        </AppLayout>
    );
};

export default Login;
