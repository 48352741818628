import React from "react";

const AppFooter = () => {
    return (
        <footer className="app-footer container-align-left">
            <p className="app-footer__notice">Payversa is not responsible for any losses or mishandling of funds as we forward origin requests to external payment handlers. We do not store, or use any client details and sensitive data in any transaction forwarded by us. All final sum values from client currency accounts are stored and referenced in USD ( $ ). Rates are calculated based on available transactional exchange rates inclusive of all administrative fees to process volume in bulk. Please visit payversa.io for more information.</p>
        </footer>
    );
};

export default AppFooter;
