import React, { useEffect } from "react";
import { observer } from "mobx-react";

import alertStore from "../../store/alertStore";

const AppAlert = observer(() => {
    useEffect(() => {
        alertStore.updateAlertMessage({ 
            message : "",
        });
        }, [])

    const onHandleDismissAlert = () => {
        alertStore.updateAlertMessage({ 
            message : "",
        });
    }

    const displayErrorMessageHandler = () => {
        if (alertStore.message){
            return alertStore.message;
        }
    }

    if(!alertStore.message) return null;
    
    return (
        <div className="app-alert">
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                {displayErrorMessageHandler()}
                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={onHandleDismissAlert}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
    );

});

export default AppAlert;
