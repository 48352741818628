import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import * as constants from "../app.constant";
import PageResetPassword from "./reset-password";
import PageLogin from "./login";
import PageDashboard from "./dashboard";
import PageMyPartnerAccount from "./my-partner-account";
import PageMyProfile from "./my-profile";
import PagePasswordReset from "./my-profile/password-reset";
import PageSettlements from "./settlements";
import PageTransactions from "./transactions";
import PageTransactionDetails from "./transactions/transactions-details";
import PagePayment from "./payment";
import { PrivateRoute } from "../app.utils";

const App = () => {
	const defaultRoute = constants.ROUTE_PATH.LOGIN;

    return (
        <BrowserRouter>
            <Routes>

				{/* protected routes */}
				<Route element={<PrivateRoute/>} >
					<Route path={ constants.ROUTE_PATH.DASHBOARD } element={ <PageDashboard/> } />
					<Route path={ constants.ROUTE_PATH.MYPARTNERACCOUNT } element={ <PageMyPartnerAccount/> } />
					<Route path={ constants.ROUTE_PATH.MYPROFILE } element={ <PageMyProfile/> } />
					<Route path={ constants.ROUTE_PATH.PASSWORDRESET } element={ <PagePasswordReset/> } />
					<Route path={ constants.ROUTE_PATH.SETTLEMENTS } element={ <PageSettlements/> } />
					<Route path={ constants.ROUTE_PATH.TRANSACTIONS } element={ <PageTransactions/> } />
					<Route path={ constants.ROUTE_PATH.TRANSACTIONDETAILS } element={ <PageTransactionDetails/> } />

				</Route>

				{/* unprotected routes */}
				<Route path={ constants.ROUTE_PATH.LOGIN } element={ <PageLogin/> } />
				<Route path={ constants.ROUTE_PATH.PAYMENT } element={ <PagePayment/> } />
				<Route path={constants.ROUTE_PATH.RESETPASSWORD} element={ <PageResetPassword/> } />

				{/* exception route */}
				<Route
					path="*"
					element={<Navigate to={ defaultRoute } replace />}
				/>

            </Routes>
        </BrowserRouter>
    );
};

export default App;
