import React, { useEffect, useState } from "react";

const AppPagination = ({ pageChangeHandler, totalRows, rowsPerPage, rowsPerPageHandler }) => {
    // Calculating max number of pages
    const noOfPages = Math.ceil(totalRows / rowsPerPage);

    // Creating an array with length equal to no.of pages
    const pagesArr = [...new Array(noOfPages)];

    // State variable to hold the current page. This value is
    // passed to the callback provided by the parent
    const [currentPage, setCurrentPage] = useState(1);

    // Navigation arrows enable/disable state
    const [canGoBack, setCanGoBack] = useState(false);
    const [canGoNext, setCanGoNext] = useState(true);

    // Onclick handlers for the butons
    const onNextPage = () => setCurrentPage(currentPage + 1);
    const onPrevPage = () => setCurrentPage(currentPage - 1);
    const onFirstPage = () => setCurrentPage(1);
    const onLastPage = () => setCurrentPage(noOfPages);
    const onPageSelect = (pageNo) => setCurrentPage(pageNo);

    // Disable previous and next buttons in the first and last page
    // respectively
    useEffect(() => {
        if (noOfPages === currentPage) {
            setCanGoNext(false);
        } else {
            setCanGoNext(true);
        }
        if (currentPage === 1) {
            setCanGoBack(false);
        } else {
            setCanGoBack(true);
        }
    }, [noOfPages, currentPage]);

    // To set the starting index of the page
    useEffect(() => {
        pageChangeHandler(currentPage);
    }, [currentPage]);

    return (
        <div className="app-table__pagination">
            {noOfPages > 1 ? (
                <>
                    <div className="app-table__pagination-page-index">
                        <button className="app-table__pagination-pages-button" type="button" onClick={onFirstPage}>
                            {"<<"}
                        </button>
                    </div>
                    <div className="app-table__pagination-page-index">
                        <button className="app-table__pagination-pages-button" type="button" onClick={onPrevPage} disabled={!canGoBack}>
                            {"<"}
                        </button>
                    </div>
                    {
                        // Creating the page numbers
                        pagesArr.map((_, index) => {
                            const page = index + 1;
                            const isActive = currentPage === page ? "app-table__pagination-pages-button--is-active" : "";
                            const start = currentPage <= 3 ? 1 : currentPage - 2;
                            const end = currentPage <= 3 ? 5 : currentPage + 2;

                            if (page >= start && page <= end) {
                                return (
                                    <div className="app-table__pagination-page-index" key={index}>
                                        <button className={`app-table__pagination-pages-button ${isActive}`} type="button" onClick={() => onPageSelect(page)}>
                                            {page}
                                        </button>
                                    </div>
                                );
                            }
                        })
                    }
                    <div className="app-table__pagination-page-index">
                        <button className="app-table__pagination-pages-button" type="button" onClick={onNextPage} disabled={!canGoNext}>
                            {">"}
                        </button>
                    </div>

                    <div className="app-table__pagination-page-index">
                        <button className="app-table__pagination-pages-button" type="button" onClick={onLastPage}>
                            {">>"}
                        </button>
                    </div>
                </>
            ) : null}
            <div>
                <select
                    className="app-table__pagination-pages-button ml-5"
                    value={rowsPerPage}
                    onChange={(e) => {
                        rowsPerPageHandler(e.target.value);
                        setCurrentPage(1);
                    }}
                >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                </select>
            </div>
        </div>
    );
};

export default AppPagination;
