import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as constants from "../../app.constant";
import moment from "moment";

import api from "../../service/api";
import { handleResponseError } from "../../app.utils";
import alertStore from "../../store/alertStore";
import profileStore from "../../store/profileStore";

const AppSideNavbar = ({isMobileBreakpoint, onToggleSideNavbar}) => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const tabNames = ["Dashboard", "Transactions", "My Partner Account", "Settlements", "My Profile"];
    const dateFormat = "DD/MM/YYYY";
    const timeFormat = "HH:mm";
    const localTime = moment().format(timeFormat);
    const localDate = moment().format(dateFormat);

    const onHandleGetProfile = async () => {
        try {
            const response = await api.get.profile();
            profileStore.updateProfile(response.data.partner);
        }
        catch(error) {
            alertStore.updateAlertMessage(handleResponseError(error));
        }
    };

    useEffect(() => {
        onHandleGetProfile();
    }, []);

    return (
        <div className="app-side-navbar">
            <div className="app-side-navbar__email">{profileStore.profileDetails?.email}</div>
            <div className="app-side-navbar__transaction-time-date-container">
                <div className="app-side-navbar__transaction-time text-bold">{localTime}</div>
                <div className="app-side-navbar__transaction-date text-bold">{localDate}</div>
            </div>
            {tabNames.map((nav, i) => {
                const sideNavKey = `side-navbar-${nav}-${i}`;
                const pathName = nav.toUpperCase().replaceAll(" ", "");
                const removeNestedPath = (path) => {
                    const removeIncludingAndAfter = path.lastIndexOf('/');
                    const isNestedPath = removeIncludingAndAfter !== 0 ;
                    const result = isNestedPath ? path.substring(0, removeIncludingAndAfter) : path;
                    return result
                }
                const path = constants.ROUTE_PATH[pathName];
                const active = removeNestedPath(location.pathname) === path;
                const currentTabClassName = active ? "app-side-navbar__nav-item app-side-navbar__nav-item--active" : "app-side-navbar__nav-item";
                const onNavigateSideNav = (path) => {
                    navigate(path);
                    isMobileBreakpoint && onToggleSideNavbar()
                }
                return (
                    <div className={currentTabClassName} key={sideNavKey} onClick={() => onNavigateSideNav(path)}>
                        <p>
                            {nav}
                        </p>
                    </div>
                );
            })}
        </div>
    );
};

export default AppSideNavbar;
