import React, { useCallback } from "react";
import { IoMenu, IoCloseOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import payversaLogo from "../../assets/images/common/payversa_logo.png"
import * as constants from "../../app.constant";

const AppHeader = ({ onToggleSideNavbar, sideNavbarActive, hasSideNavbar, isMobileBreakpoint }) => {
    const navigate = useNavigate();

    // const [sideNavbarActive, setSideNavbarActive] = useState(false);

    // const onToggleSideNavbar = useCallback(() => {
    //     setSideNavbarActive((active) => !active);
    // }, []);

    //[TODO] logout
    // const onHandleLogout = useCallback(() => {
    //     const logoutPathname = getPrefixUrl(pathnames.pageHome);
    //     const logoutAction = { type: "logout" };
    //     localStorage.clear();
    //     dispatch(logoutAction);
    //     history.push(logoutPathname);
    // }, [history, dispatch]);

    //[TODO] temporary logout
    const onHandleLogout = useCallback(() => {
        const onNavigateToLogout = () => {
            navigate(constants.ROUTE_PATH.LOGIN);
        }; 

        localStorage.clear();
        onNavigateToLogout();
    }, [navigate]);

    return (
        <header className="app-header">
            <div className="app-header__container">
                {hasSideNavbar && isMobileBreakpoint && (
                    <div className="app-header__sidebar-button-container">
                        <button className="app-header__sidebar-button" onClick={onToggleSideNavbar}>
                            {sideNavbarActive ? <IoCloseOutline /> : <IoMenu />}
                        </button>
                    </div>
                )}

                <div className="app-header__logo">
                    <img src={payversaLogo} alt="payversa" />
                </div>

                <div className="app-header__logout-button-container">
                    <button className="app-header__logout-button btn btn-primary-purple btn-m" onClick={() => onHandleLogout()}>Logout</button>
                </div>
            </div>
        </header>
    );
};

export default AppHeader;
