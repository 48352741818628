import React, { useImperativeHandle, forwardRef, useState, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import InputField from "../../form/input-field";

const ForgetPasswordModal = (props, ref) => {
    const [visible, setVisible] = useState(false);

    const onHandleShow = () => {
        setVisible(true);
    };

    const onHandleHide = () => {
        setVisible(false);
        formik.resetForm();
    };

    useImperativeHandle(ref, () => {
        return {        
            onHandleShow: () => onHandleShow(),
            onHandleHide: () => onHandleHide(),
        }
    });

    const onHandleGetModalClassName = useMemo(() => {
        const classNames = ["alert-modal", "alert-modal--hidden"];

        if (visible) classNames.pop();

        return classNames.join(" ");
    }, [visible]);
    
    const formik = useFormik({
        initialValues: {
            forgetPasswordEmail: "",
        },
        validationSchema: Yup.object({
            forgetPasswordEmail: Yup.string()
                .email("invalid email format")
                .required("please enter email"),
        }),
        onSubmit: (values, action) => {
            props.onHandleForgetPassword(values)
        },
    });

    return (
        <>
            <div className={"forget-password-modal " + onHandleGetModalClassName}>
                <div className="modal-wrapper">
                    <form
                        className="forget-password__form"
                        noValidate
                        autoComplete="off"
                    >
                        <div className="modal-header">
                            <p className="forget-password-modal__title text-bold">Forget Password</p>
                            <p className="forget-password-modal__subtitle text-bold">Please enter your account registered email to receive reset password link.</p>
                        </div>
                        <div className="modal-body"> 
                            <div className="form-row">
                                <div className="col-6">
                                    <InputField
                                        label="Email"
                                        type="email"
                                        name="forgetPasswordEmail"
                                        formik={formik}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <p className="btn-container">
                                <button className="btn btn-primary-purple btn-m" type="button" disabled={formik.isSubmitting} onClick={formik.handleSubmit}>Next</button>
                            </p>
                            <p className="btn-container">
                                <button className="btn btn-secondary-red btn-m" type="button" onClick={props.onHandleCancel}>Cancel</button>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default forwardRef(ForgetPasswordModal);
