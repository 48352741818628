import React from "react";
import { Circles } from "react-loader-spinner";

const AppLoadingSpinner = props => {
    return (
        props.isVisible && (
            <div className="app-loading-spinner">
                <Circles type="Oval" color="#000000" height={100} width={100} />
            </div>
        )
    );
};

export default AppLoadingSpinner;
