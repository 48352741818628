export const ROUTE_PATH = {
    PAYMENT: "/payment",
    RESETPASSWORD: "/resetPassword",
    LOGIN: "/login",
    DASHBOARD: "/dashboard",
    MYPARTNERACCOUNT: "/my-partner-account",
    MYPROFILE: "/my-profile",
    PASSWORDRESET: "/my-profile/password-reset",
    SETTLEMENTS: "/settlements",
    TRANSACTIONS: "/transactions",
    TRANSACTIONDETAILS: "/transactions/details",
};

export const ENDPOINT_PATH = {
    LOGIN: "/user/login",
    FORGOT_PASSWORD: "/user/forgotPassword",
    RESET_PASSWORD: "/user/resetPassword",
    CHANGE_PASSWORD: "/user/changePassword",
    GET_DASHBOARD: "/partner/dashboard",
    PROFILE: "/partner/profile",
    PARTNER_ACCOUNTS: "/partner/accounts",
    ACCOUNT_TRANSACTIONS: "/transaction/account/",
    ACCOUNT_BALANCE_HISTORY: "/account/balanceHistory/",
    RESEND_CALLBACK: "/transaction/resendCallback",
    SETTLEMENTS: "/settlement/partner",
    GATEWAY_GET_ACCESS_TOKEN: "/gateway/getAccessToken",
    GATEWAY_SUBMIT: "/gateway/submit",
    GATEWAY_CANCEL: "/gateway/cancel",
    NEW_SETTLEMENT: "/settlement/",
    REQUEST_TAC: "/partner/sendTacCode",
};

export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm"