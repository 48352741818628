import { makeAutoObservable } from "mobx";
import { makePersistable } from 'mobx-persist-store';

class AlertStore {
    constructor() {
        makeAutoObservable(this);
        makePersistable(this, { name: "AlertStore", properties: ["message"], storage: window.localStorage });
    }

    message = "";

    updateAlertMessage = (error) => {
        this.message = error.message;
    }
}

const alertStore = new AlertStore();

export default alertStore;
