export const CellFilter = ({ column: { filterValue, setFilter } }) => {
  return (
    <input
      value={filterValue === undefined ? "" : filterValue}
      placeholder={" search filter..."}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    />
  );
};

export const SelectCellFilter = ({ column: { filterValue, setFilter } }) => {
  return (
    <select
      value={filterValue === undefined ? "" : filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">search filter...</option>
      <option value="SUCCESS">Success</option>
      <option value="PENDING">Pending</option>
      <option value="FAILED">Failed</option>
      <option value="CANCELLED">Cancelled</option>
    </select>
  );
};

export const txTypeSelectCellFilter = ({ column: { filterValue, setFilter } }) => {
  return (
    <select
      value={filterValue === undefined ? "" : filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">search filter...</option>
      <option value="Payin">Payin</option>
      <option value="Payout">Payout</option>
    </select>
  );
};
