import React, {useEffect, useState} from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';

import AppLayout from "../components/shared/app-layout";
import { numberToMoneyFormat, dateTimeFormatting, handleResponseError} from "../app.utils";
import Table from "../components/shared/app-table";
import api from "../service/api";
import * as constants from "../app.constant";
import alertStore from "../store/alertStore";

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState([]);
    const currency = "USD";
    Chart.register(...registerables);

    const transactionGraphLabel = dashboardData?.graph?.transactionsGraph?.map(graphData => graphData.day);
    const transactionGraphAmount = dashboardData?.graph?.transactionsGraph?.map(graphData => graphData.amount);
    const settlementGraphLabel = dashboardData?.graph?.settlementGraph?.map(graphData => graphData.day);
    const settlementGraphAmount = dashboardData?.graph?.settlementGraph?.map(graphData => graphData.amount);

    const isSettlementGraphLabelMoreDays = settlementGraphLabel?.length >= transactionGraphLabel?.length;

    const dashboardStatusDisplayMapping = (backEndValue) => {
        switch (backEndValue) {
            case "SUCCESS":
                return "Success";
            case "PENDING":
                return "Pending";
            case "FAILED":
                return "Failed";
            case "CANCELLED":
                return "Cancelled";
            case "ERROR":
                return "Error";
        }
    };

    const dataGraph = {
        labels: isSettlementGraphLabelMoreDays ? settlementGraphLabel : transactionGraphLabel,
        datasets: [
          {
            label: "Transactions in ( USD )",
            data: transactionGraphAmount,
            fill: false,
            borderColor: "#ED702D",
            spanGaps: true
          },
          {
            label: "Settlements in ( USD )",
            data: settlementGraphAmount,
            fill: false,
            borderColor: "#EB4848",
            spanGaps: true
          }
        ]
    };
      
    const options = {
        plugins: {
            legend: {
                display: true,
                position: "bottom",
                align: "start",
                labels: {
                    color: 'rgb(255, 99, 132)',
                    boxHeight: 1,
                    boxWidth: 50,
                }
            }
        },
        scales: {
            x: {
                offset: true,
            }
        },
        elements: {
            point: {
                borderWidth: 3,
                radius: 2,
            },
        },
        options: {
            responsive: false,
            maintainAspectRatio: false
          },
    };

    const columnsSettlements = [            
        {
            Header: "Settlement ID",
            accessor: "settlementId",
            Filter: false,
        },
        {
            Header: "Date Requested",
            accessor: "createdAt",
            Filter: false,
            Cell: props => dateTimeFormatting(props.value)
        },
        {
            Header: "Amount",
            accessor: "amount",
            Filter: false,
        },
        {
            Header: "Status",
            accessor: "status",
            Filter: false,
            Cell: props => <div className={`cell-status cell-status--${props.value.toLowerCase()}`}>{dashboardStatusDisplayMapping(props.value)}</div>
        },
        {
            Header: "Destination",
            accessor: "destinationAccount",
            Filter: false,
        },
    ];

    const columnsFundIn = [
        {
            Header: "Merchant TXID",
            accessor: "merchantTxId",
            Filter: false,
        },
        {
            Header: "Partner TXID",
            accessor: "partnerTxId",
            Filter: false,
        },
        {
            Header: "Amount",
            accessor: "partnerAmount",
            Filter: false,
        },
        {
            Header: "Status",
            accessor: "txStatus",
            Filter: false,
            Cell: props => <div className={`cell-status cell-status--${props.value.toLowerCase()}`}>{dashboardStatusDisplayMapping(props.value)}</div>
        },
        {
            Header: "Account",
            accessor: "account.name",
            Filter: false,
        },
        {
            Header: "D. Submitted",
            accessor: "createdAt",
            Filter: false,
            Cell: props => dateTimeFormatting(props.value)
        },
        {
            Header: "D. Proc",
            accessor: "processedAt",
            Filter: false,
            Cell: props => dateTimeFormatting(props.value)
        }
    ];

    const onHandleGetDashboardData = async () => {
        try {
            const response = await api.get.getDashboard();
            setDashboardData(response.data);
        }
        catch(error) {
            alertStore.updateAlertMessage(handleResponseError(error));
        }
    };

    useEffect(() => {
        onHandleGetDashboardData();
    }, []);

    return (
        <AppLayout hasHeader={true} hasFooter={true} hasSideNavbar={true}>
            <div className="app-page page-dashboard">
                <div className="dashboard">
                    <div className="container-align-left">
                        <div className="dashboard__tile-container">
                            <div className="dashboard__tile shadow">
                                <div>
                                    <p className="dashboard__tile-title text-bold">{dashboardData?.currentMonthName}</p>
                                    <p className="dashboard__subtitle">This month's value</p>
                                </div>
                                <p className="dashboard__tile-value-container text-bold">
                                    <span className="dashboard__tile-value">{numberToMoneyFormat(dashboardData?.thisMonthValue)}</span> <span className="dashboard__tile-currency">{currency}</span>
                                </p>
                                <div className="dashboard__tile-white-line"></div>
                            </div>
                            <div className="dashboard__tile shadow">
                                <div>
                                    <p className="dashboard__tile-title text-bold">{dashboardData?.currentMonthName}</p>
                                    <p className="dashboard__subtitle">This month's transactions</p>
                                </div>
                                <p className="dashboard__tile-value-container text-bold">
                                    <span className="dashboard__tile-value">{dashboardData?.transactions}</span>
                                </p>
                                <div className="dashboard__tile-white-line"></div>
                            </div>
                            <div className="dashboard__tile shadow">
                                <div>
                                    <p className="dashboard__tile-title text-bold">Current Balance</p>
                                    <p className="dashboard__subtitle">Total Account Balance</p>
                                </div>
                                <p className="dashboard__tile-value-container text-bold">
                                    <span className="dashboard__tile-value">{numberToMoneyFormat(dashboardData?.totalAccountBalance)}</span> <span className="dashboard__tile-currency">{currency}</span>
                                    </p>
                                <div className="dashboard__tile-white-line"></div>
                            </div>
                        </div>

                        <div className="dashboard__graph-container shadow">
                            <p className="dashboard__title">{dashboardData?.currentMonthName}</p>
                            <p className="dashboard__subtitle">This month's transactions</p>
                            <div className="dashboard__graph-wrapper">
                                <div className="dashboard__graph">
                                    <Line options={options} data={dataGraph} />
                                </div>
                            </div>
                        </div>
                        
                        <div className="dashboard__table-container shadow">
                            <p className="dashboard__title">Settlements</p>
                            <p className="dashboard__subtitle">Last 5 Transactions</p>
                            {dashboardData.settlements && (
                                <Table
                                    columns={columnsSettlements}
                                    data={dashboardData.settlements}
                                />
                            )}
                        </div>

                        <div className="dashboard__table-container shadow">
                            <p className="dashboard__title">Fund In</p>
                            <p className="dashboard__subtitle">Last 5 Transactions</p>
                            {dashboardData.fundIn && (
                                <Table
                                    columns={columnsFundIn}
                                    data={dashboardData.fundIn}
                                />
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default Dashboard;
