import React from "react";
import { Navigate, Outlet } from 'react-router-dom'
import moment from "moment";

import * as constants from "./app.constant";

const defaultRoute = constants.ROUTE_PATH.LOGIN;

export const numberToMoneyFormat = (value, decimal = 2) => {
    const number = parseFloat(value);
    return number.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
};

export const handleResponseError = (e) => {
    let error = e;
    const isUnauth = error?.response?.status === 401;

    // logout if api returns 401 unauth(session expires)
    if (isUnauth) {
        window.location.href = defaultRoute;
    }

    if (typeof e === "object") {
        if (!!e.response) {
            if (!!e.response.data) {
                error = e.response.data;
            } else {
                error = e.response;
            }
        } else if (!!e.data) {
            error = e.data;
        }

        return { message: error.message };
        
    } else {
        error = e;
        return { message: error };
    }
};

export const PrivateRoute = () => {
    const auth = localStorage.getItem("@storage_token");

    return (
        auth ? <Outlet/> : <Navigate to={defaultRoute}/>
    );
    
}

export const dateTimeFormatting = (value) => {
    if (value) {
        return moment(value).format(constants.DATE_TIME_FORMAT);
    } else {
        return "invalid date";
    }
}