import jwt from "jwt-decode";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import * as constants from "../app.constant";
import { handleResponseError, numberToMoneyFormat } from "../app.utils";
import cardTransferHeaderLogo from "../assets/images/pages/page-payment/icon-instant-card-transfer-header.png";
import cardTransferLogo from "../assets/images/pages/page-payment/icon-instant-card-transfer.png";
import instantTransferHeaderLogo from "../assets/images/pages/page-payment/icon-instant-transfer-header.png";
import instantTransferLogo from "../assets/images/pages/page-payment/icon-instant-transfer.png";
import paymentHeaderPayversaLogo from "../assets/images/pages/page-payment/icon-payment-page-payversa-logo.png";
import paymentHeaderSecureLogo from "../assets/images/pages/page-payment/icon-payments-page-header-secure.png";
import thirdPartyTransferLogo from "../assets/images/pages/page-payment/icon-third-party-transfer.png";
import AppInfoBox from "../components/shared/app-info-box";
import AppLayout from "../components/shared/app-layout";
import api from "../service/api";
import alertStore from "../store/alertStore";

const inputs = [
  {
    img: cardTransferLogo,
    headerLogo: cardTransferHeaderLogo,
    headerText: "Debit & Credit Card",
    headerDescription: "Fast. Safe. Efficient. Use your favourite debit & credit cards at ease.",
    value: "CREDIT_CARD",
  },
  {
    img: instantTransferLogo,
    headerLogo: instantTransferHeaderLogo,
    headerText: "Instant Transfer",
    headerDescription: "The fastest solution to online banking. Use your favourite banks to transact, safe and quick.",
    value: "INSTANT_TRANSFER",
  },
  {
    img: thirdPartyTransferLogo,
    headerLogo: instantTransferHeaderLogo,
    headerText: "Third Party Transfer",
    headerDescription: "Standard online banking payment method.",
    value: "THIRD_PARTY",
  },
];

const Payment = () => {
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedDefaultGateway, setSelectedDefaultGateway] = useState(null);
  const [gatewayData, setGatewayData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const onHandleSelectOption = (index) => {
    setSelectedOption(inputs[index]);
  };

  const getPageDetails = async (searchParams) => {
    try {
      const payload = {
        linkHash: searchParams,
      };
      const response = await api.post.gatewayGetAccessToken(payload);
      const token = response.data.token;
      const decrytedGatewayData = jwt(token);
      const isExistingUserDefaultGateway = decrytedGatewayData?.default;

      localStorage.setItem("@storage_payment-page-token", token);
      setGatewayData(decrytedGatewayData);

      if (isExistingUserDefaultGateway) {
        const findInputNameSameWithDefaultGateway = inputs.find((input) => input.value === decrytedGatewayData.default);
        const setSelectedDefaultGatewayToExistingUserDefaultGateway = () => {
          setSelectedDefaultGateway(decrytedGatewayData.default);
        };
        const setSelectedOptionToExistingUserDefaultGateway = () => {
          setSelectedOption(findInputNameSameWithDefaultGateway);
        };

        setSelectedOptionToExistingUserDefaultGateway();
        setSelectedDefaultGatewayToExistingUserDefaultGateway();
      }

      setIsLoading(false);
    } catch (error) {
      alertStore.updateAlertMessage(handleResponseError(error));
      setIsLoading(false);
      setIsError(true);
    }
  };

  const onHandleCancelPayment = async () => {
    setIsLoading(true);
    try {
      const response = await api.post.gatewayCancel();
      localStorage.removeItem("@storage_payment-page-token");
      window.location.href = response.data.data;
      setIsLoading(false);
    } catch (error) {
      alertStore.updateAlertMessage(handleResponseError(error));
      setIsLoading(false);
    }
  };

  const onHandlePayPayment = async () => {
    setIsLoading(true);
    try {
      if (selectedOption === null) throw new Error("Please select payment method.");

      const payload = {
        pspMethod: selectedOption.value,
        default: selectedDefaultGateway,
      };
      const response = await api.post.gatewaySubmit(payload);
      let hiddenDiv = document.getElementById("hidden-form-post-payment");

      if (response.data.type === "redirect") {
        window.location.href = response.data.data;
      } else if (response.data.type === "form") {
        hiddenDiv.innerHTML = response.data.data;
        hiddenDiv.querySelector("#submitButton").click();
      }
      setIsLoading(false);
    } catch (error) {
      alertStore.updateAlertMessage(handleResponseError(error));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    if (searchParams?.hash) {
      getPageDetails(searchParams?.hash);

      setCountdown(300);
      setInterval(() => {
        setCountdown((countdown) => countdown - 1);
      }, 1000);

      setTimeout(onHandleCancelPayment, 299 * 1000);
    }
  }, []);

  return (
    <AppLayout isLoading={isLoading}>
      <div className="app-page page-payment">
        <div className="payment">
          <div id="hidden-form-post-payment" style={{ display: "none" }}></div>

          <div className="payment__header">
            <div className="payment__header-logo-1">
              <img src={paymentHeaderPayversaLogo} alt="" />
            </div>
            <div className="payment__header-logo-2">
              <img src={paymentHeaderSecureLogo} alt="" />
            </div>
          </div>

          <div className="container">
            {isError ? (
              <div className="payment__box-wrapper" style={{ alignItems: "center" }}>
                <div className="payment__info">
                  <AppInfoBox message={"An error has occurred. Please try again."} />
                  <div className="payment__info-button">
                    <button className="btn btn-primary btn-m w-100" style={{ marginTop: "10px" }} disabled={isLoading} onClick={() => onHandleCancelPayment()}>
                      Back to Merchant
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="payment__box-wrapper">
                <div className="payment__box-left">
                  <div className="payment__info">
                    <AppInfoBox message={"Instant Transfer gateway is only available from 5:00 AM to 11:00 PM daily. Please ensure that you do not refresh, close or click back when performing a transaction. We cannot guarantee a successful transaction if the payment process is interrupted."} />
                  </div>

                  <div className="payment__select-payment">
                    {inputs.map((input, index) => {
                      const inputName = input.value;
                      const key = `${inputName}-${index}`;
                      const selected = inputName === selectedOption?.value;
                      const active = selected ? "payment__select-payment-button--is-active" : "";
                      const isAvailableOption = gatewayData?.enabled[inputName] === true;
                      const inputLogo = input.img;
                      const inputHeaderLogo = input.headerLogo;
                      const inputHeader = input.headerText;
                      const inputDescription = input.headerDescription;
                      const isDefault = inputName === selectedDefaultGateway;

                      return isAvailableOption ? (
                        <div className={`payment__select-payment-button ${active}`} key={key} type="button" onClick={() => onHandleSelectOption(index)}>
                          <div className="payment__select-payment-logo">
                            <img className="img-fluid" src={inputLogo} alt="" />
                          </div>

                          <div className="payment__select-payment-details">
                            <p className="payment__select-payment-details-header">
                              <span className="payment__select-payment-details-header-logo">
                                <img src={inputHeaderLogo} alt="" />
                              </span>
                              <span className="payment__select-payment-details-header-text text-bold">{inputHeader}</span>
                            </p>

                            <p className="payment__select-payment-details-description">{inputDescription}</p>

                            <p className="btn-container">
                              <button className="btn btn-primary btn-m" onClick={() => onHandleSelectOption(index)}>
                                {selected ? "Selected" : "Select"}
                              </button>
                              <button className="btn btn-outline btn-m" disabled={!selected} onClick={() => setSelectedDefaultGateway(inputName)}>
                                {isDefault ? "Default" : "Set As Detault"}
                              </button>
                            </p>
                          </div>
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>

                <div className="payment__box-right">
                  <div className="payment__bill-container">
                    <div className="payment__bill-overlay-container">
                      <p className="payment__bill-title text-bold">Total Bill</p>
                      <div className="payment__bill-values">
                        <p className="payment__bill-values-usd text-bold">
                          {numberToMoneyFormat(gatewayData?.usdAmount)} <small>USD</small>
                        </p>
                        <p className="mb-0">you have to pay</p>
                        <p className="payment__bill-values-myr text-bold">
                          <small>MYR</small> {numberToMoneyFormat(gatewayData?.amount)}
                        </p>
                      </div>
                      <div className="payment__bill-details">
                        <p className="payment__bill-details-field-value">
                          <span className="payment__bill-details-field text-bold">Email:</span>
                          <span className="payment__bill-details-value">{gatewayData?.email}</span>
                        </p>
                        <p className="payment__bill-details-field-value">
                          <span className="payment__bill-details-field text-bold">Transaction ID:</span>
                          <span className="payment__bill-details-value">{gatewayData?.merchantTxId}</span>
                        </p>
                        <p className="payment__bill-details-field-value">
                          <span className="payment__bill-details-field text-bold">Time/Date:</span>
                          <span className="payment__bill-details-value">{moment(gatewayData?.dateTime).format(constants.DATE_TIME_FORMAT)}</span>
                        </p>
                      </div>
                    </div>
                    <p className="payment__bill-disclamer">By clicking Pay Now you agree to our terms of agreement and acknowledge our terms & conditions.</p>
                    <p className="btn-container">
                      <div>
                        <p>Session expire in {moment.utc(countdown * 1000).format("mm:ss")}</p>
                      </div>
                      <button className="btn btn-primary btn-m w-100" disabled={isLoading} onClick={() => onHandlePayPayment()}>
                        Pay Now
                      </button>
                      <button className="btn btn-outline btn-m w-100" disabled={isLoading} onClick={() => onHandleCancelPayment()}>
                        Cancel
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="payment__footer container">
            <p>Payversa is not responsible for any losses or mishandling of funds as we forward origin requests to external payment handlers. We do not store, or use any client details and sensitive data in any transaction forwarded by us. Please visit payversa.io for more information.</p>
            <a href="https://payversa.io" target="_blank" rel="noreferrer">
              www.payversa.io
            </a>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Payment;
