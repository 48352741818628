import React, { useImperativeHandle, forwardRef, useState, useMemo, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import AppInfoBox from "../../shared/app-info-box";
import { numberToMoneyFormat } from "../../../app.utils";
import InputField from "../../form/input-field";
import SelectField from "../../form/select-field";

const RequestSettlementModal = (props, ref) => {
  const [visible, setVisible] = useState(false);

  const onHandleShow = () => {
    setVisible(true);
  };

  const onHandleHide = () => {
    setVisible(false);
    formik.resetForm();
  };

  useImperativeHandle(ref, () => {
    return {
      onHandleShow: () => onHandleShow(),
      onHandleHide: () => onHandleHide(),
    };
  });

  const onHandleGetModalClassName = useMemo(() => {
    const classNames = ["alert-modal", "alert-modal--hidden"];

    if (visible) classNames.pop();

    return classNames.join(" ");
  }, [visible]);

  const accountsData = props.accountsData;

  const formik = useFormik({
    initialValues: {
      amount: "",
      selectedAccount: "",
      destinationAccount: "Wallet - USDT",
      walletNetwork: "",
      walletAddress: "",
      tacCode: "",
    },
    validationSchema: Yup.object({
      amount: Yup.string().required("please enter amount"),
      selectedAccount: Yup.string().required("please select account"),
      walletNetwork: Yup.string().required("please select wallet network"),
      walletAddress: Yup.string().required("please enter walet address"),
      tacCode: Yup.string().required("please enter TAC code"),
    }),
    onSubmit: (values, action) => {
      props.onHandleCreateNewSettlement(values, formik);
    },
  });

  const selectAccountOptions = accountsData?.map((account) => ({
    label: account.name,
    value: account.id,
  }));

  const walletNetworkOptions = [
    {
      label: "ERC20",
      value: "ERC20",
    },
    {
      label: "TRC20",
      value: "TRC20",
    },
  ];

  const destinationAccountOptions = [
    {
      label: "Wallet - USDT",
      value: "Wallet - USD",
    },
  ];

  const onHandleRequestTac = () => {
    formik.setSubmitting(true);
    props.onHandleRequestTac(formik);
  };

  return (
    <>
      <div className={"request-settlement-modal " + onHandleGetModalClassName}>
        <div className="modal-wrapper">
          <form className="review-order__form" noValidate autoComplete="off">
            <div className="modal-header">
              <p className="request-settlement-modal__title text-bold">Request Settlement</p>
              <p className="request-settlement-modal__subtitle text-bold">Request Settlement to wallet or bank account</p>
            </div>
            <div className="modal-body">
              <div className="form-row">
                <div className="col-3">
                  <div className="request-settlement-modal__tile" style={{ backgroundColor: "#48458D" }}>
                    <div>
                      <p className="request-settlement-modal__tile-title text-bold">{accountsData[0]?.name} Fund Balance</p>
                      <p className="request-settlement-modal__tile-subtitle">Total Currency Account Balance</p>
                    </div>
                    <div className="request-settlement-modal__tile-value-container text-bold">
                      <span className="request-settlement-modal__tile-value">{numberToMoneyFormat(accountsData[0]?.balance)}</span> <span className="request-settlement-modal__tile-currency">{accountsData[0]?.accountCurrency}</span>
                    </div>
                    <div className="request-settlement-modal__tile-white-line"></div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="request-settlement-modal__tile" style={{ backgroundColor: "#A45858" }}>
                    <div>
                      <p className="request-settlement-modal__tile-title text-bold">{accountsData[1]?.name} Fund Balance</p>
                      <p className="request-settlement-modal__tile-subtitle">Total Currency Account Balance</p>
                    </div>
                    <div className="request-settlement-modal__tile-value-container text-bold">
                      <span className="request-settlement-modal__tile-value">{numberToMoneyFormat(accountsData[1]?.balance)}</span> <span className="request-settlement-modal__tile-currency">{accountsData[1]?.accountCurrency}</span>
                    </div>
                    <div className="request-settlement-modal__tile-white-line"></div>
                  </div>
                </div>
                <div className="col-3">
                  <InputField label="Amount (local currency)" type="number" name="amount" formik={formik} />
                </div>
                <div className="col-3">
                  <SelectField label="Select Account" placeholder="Please select" name="selectedAccount" options={selectAccountOptions} formik={formik} />
                </div>
              </div>

              <div className="form-row">
                <div className="col-3">
                  <SelectField label="Destination Account" disabled={true} options={destinationAccountOptions} name="destinationAccount" formik={formik} />
                </div>
                <div className="col-3">
                  <SelectField label="Wallet Network" placeholder="Please select" name="walletNetwork" options={walletNetworkOptions} formik={formik} />
                </div>
                <div className="col-3">
                  <InputField label="Wallet address" placeholder="Please Enter Amount" name="walletAddress" formik={formik} />
                </div>
                <div className="col-3">
                  <InputField label="TAC Code" placeholder="Please Enter Amount" name="tacCode" type="number" formik={formik} />
                  <button type="button" className="request-settlement-modal__tac-button" disabled={formik.isSubmitting} onClick={() => onHandleRequestTac()}>
                    Request TAC
                  </button>
                </div>
              </div>

              <div className="request-settlement-modal__info">
                <AppInfoBox message={"Please ensure that your destination account and wallet network is selected properly as we will not be responsible for any lost funds during a mislabeled or improper transaction."} />
              </div>
            </div>
            <div className="modal-footer">
              <p className="btn-container">
                <button className="btn btn-primary-purple btn-m" type="button" disabled={formik.isSubmitting} onClick={formik.handleSubmit}>
                  Done
                </button>
              </p>
              <p className="btn-container">
                <button className="btn btn-secondary-red btn-m" type="button" onClick={props.onHandleCancel}>
                  Cancel
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default forwardRef(RequestSettlementModal);
