import { makeAutoObservable } from "mobx";
import { makePersistable } from 'mobx-persist-store';

class TransactionsDetailsStore {
    constructor() {
        makeAutoObservable(this);
    }
    
    selectedTransaction = [];

    updateSelectedTransaction = (selectedTransaction) => {
        this.selectedTransaction = selectedTransaction;
    }
}

const transactionsDetailsStore = new TransactionsDetailsStore();

export default transactionsDetailsStore;
